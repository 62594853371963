<template>
  <Navbar :Msg="'Voltar'" :rota="'admin/despedidas'" />
  <v-card class="container" style="min-height: 78vh !important;">
    <v-stepper v-model="currentStep" color="red" :elevation="0" :items="['Falecido', 'Moderador', 'Despedida']"
      ref="stepper" v-if="!loading">
      <template v-slot:item.1>
        <v-card flat>
          <v-container>
            <v-card class="mx-auto" max-width="1000" min-height="58vh" elevation="0" style="border: 1px solid #343F53;">
              <v-toolbar color="#343F53">
                <v-card-title class="headline font-weight-bold d-flex align-center justify-space-between px-4 w-100">
                  <spam style="color: white;">Falecido</spam>
                  <v-btn color="white" small class="mr-2" style="font-weight:bold" @click="dialogFalecido = true">
                    Localizar Falecido
                    <v-icon class="ml-2" dark>mdi-magnify</v-icon>
                  </v-btn>
                </v-card-title>

              </v-toolbar>

              <v-card-text>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="falecido.firstname" :counter="100" :rules="nameRules" label="Nome"
                        hide-details required class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="falecido.birthdate" :rules="dateRules" label="Data de Nascimento"
                        hide-details required type="date" class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="falecido.deathdate" :rules="dateRules" label="Data de Falecimento"
                        hide-details type="date" class="custom-text-field" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="CEP" v-model="falecido.cep" v-mask="'#####-###'" @blur="consultarCEP"
                        :loading="loadingCEP"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="falecido.birthCountry" label="País de Nascimento" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="falecido.birthState" label="Estado de Nascimento" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="falecido.birthCity" label="Cidade de Nascimento" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input v-model="falecido.profilePicture" label="Foto de Perfil" hide-details
                        accept="image/*" class="custom-file-input"></v-file-input>
                    </v-col>
                    <v-col cols="12">
                      <v-switch inset v-model="despedida.showPortal" label="Publicar no Portal" color="blue"></v-switch>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>

            <v-dialog v-model="dialogFalecido" max-width="800" height="70vh" persistent>
              <v-card>
                <v-toolbar color="#343F53">
                  <v-card-title class="text-h5 grey lighten-2">
                    <span class="font-weight-bold">Pesquisar por CPF</span>
                  </v-card-title>
                </v-toolbar>
                <v-card-text>
                  <v-card class="search d-flex align-center" elevation="0">
                    <v-text-field v-model="cpf" label="CPF" hide-details v-mask="'###.###.###-##'"
                      class="flex-grow-1"></v-text-field>
                    <v-btn color="#343F53" @click="searchCPF" class="ml-2" :loading="loadingCPF" :disabled="loadingCPF">
                      Pesquisar
                    </v-btn>

                  </v-card>

                  <v-data-table :items-per-page="itemsPerPage" :headers="headers" :items="cpfResults"
                    items-per-page-text="Falecidos por página" :pageText="'{0}-{1} de {2}'">
                    <template #item.action="{ item }">
                      <v-btn color="#343F53" variant="outlined" @click="selectItem(item, 'falecido')">Adicionar</v-btn>
                    </template>

                    <!-- Template para customizar a coluna 'nome' -->
                    <template #item.nome="{ item }">
                      <div style="margin-top: 8px;">
                        <strong>Nome:</strong> {{ item.nome }} <br>
                        <strong>Falecimento:</strong> {{ formatDate(item.falecimento) || '' }} <br>
                        <strong>Nascimento:</strong> {{ formatDate(item.nascimento) || '' }} <br>
                        <strong>Contrato:</strong> {{ item.num_contrato || '' }} <br>
                        <strong>Tipo:</strong> {{ item.tipo || 'N/A' }} <br>
                        <strong>Cemitério:</strong> {{ item.cemiterio || '' }} <br>
                        <strong>Jazigo:</strong> {{ item.localizacao || '' }}
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="dialogFalecido = false">Fechar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-card>
      </template>

      <template v-slot:item.2>
        <v-card flat>
          <v-container class="container">
            <v-card class="mx-auto" max-width="1000" elevation="0" style="border: 1px solid #343F53;">
              <v-toolbar color="#343F53">
                <v-card-title class="headline font-weight-bold d-flex align-center justify-space-between px-4 w-100">
                  <spam style="color: white;">Moderador</spam>
                </v-card-title>

              </v-toolbar>
              <v-card-text>
                <v-form v-model="valid" class="pa-4">
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field :readonly="codigoValidado" v-model="moderador.phone" :rules="phoneRules"
                        label="Telefone do Moderador" hide-details v-mask="'(##) #####-####'" required
                        class="custom-text-field">
                      </v-text-field>
                    </v-col>
                    <!-- Código de Verificação -->
                    <v-col cols="12" md="6" v-if="isVisibleToken">
                      <v-otp-input v-model="moderador.token" :disabled="codigoValidado || loadingValidCod" :length="5"
                        class="custom-otp-input" hide-details required></v-otp-input>
                    </v-col>

                    <v-col cols="12" class="d-flex justify-start">
                      <v-btn color="#343F53"
                        :disabled="isWaiting || codigoValidado || loadingEnvioCod || loadingValidCod"
                        @click="enviarToken" size="small" variant="outlined" class="mr-2" :loading="loadingEnvioCod">
                        {{ codigoEnviado ?
                          (isWaiting ? `Reenviar (${tempoRestante}s)` : 'Reenviar Código') : 'Enviar Código' }}
                      </v-btn>

                      <v-btn v-if="isVisibleToken" color="#343F53" @click="verificarToken" size="small" variant="flat"
                        :disabled="codigoValidado || loadingValidCod" :loading="loadingValidCod">
                        Validar Código
                      </v-btn>
                    </v-col>


                    <template v-if="isVisible">
                      <v-col cols="12">
                        <v-text-field v-model="moderador.nome" :rules="nameRules" label="Nome" hide-details required
                          class="custom-text-field"></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field :disabled="moderadorExistente" v-model="moderador.email" :rules="emailRules"
                          label="E-mail do Moderador" hide-details required class="custom-text-field"></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field :disabled="moderadorExistente" v-model="moderador.password"
                          :type="showPassword ? 'text' : 'password'" :rules="passwordRules" label="Senha do Moderador"
                          hide-details required class="custom-text-field">
                          <template v-slot:append-inner>
                            <v-icon @click="showPassword = !showPassword" class="cursor-pointer">
                              {{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}
                            </v-icon>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-select v-model="moderador.cliente" label="Moderador é cliente?"
                          :items="['Sim, cliente do Grupo', 'Ainda não']" required></v-select>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-select v-model="moderador.parentesco" label="Parentesco com falecido?"
                          :items="['Não informado', 'Sim', 'Não']" clearable required></v-select>
                      </v-col>

                      <v-col cols="12" md="6" v-if="moderador.parentesco === 'Sim'">
                        <v-autocomplete v-model="moderador.tipoParentesco" label="Qual o parentesco?" :items="[
                          'Pai', 'Mãe', 'Filho(a)', 'Irmão(ã)', 'Avô(ó)',
                          'Tio(a)', 'Sobrinho(a)', 'Primo(a)', 'Cônjuge',
                          'Amigo(a)', 'Outros'
                        ]" clearable required></v-autocomplete>
                      </v-col>
                    </template>
                  </v-row>
                </v-form>


              </v-card-text>
            </v-card>
          </v-container>
        </v-card>
      </template>

      <template v-slot:item.3>
        <v-card flat>
          <v-container class="container">
            <v-card class="mx-auto" max-width="1000" elevation="0" style="border: 1px solid #343F53;">
              <v-toolbar color="#343F53">
                <v-card-title class="headline font-weight-bold d-flex align-center justify-space-between px-4 w-100">
                  <spam style="color: white;">Despedida</spam>
                </v-card-title>
              </v-toolbar>
              <v-card-text>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="despedida.cemiterio" :counter="100" label="Cemitério" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="despedida.jazigo" :counter="100" label="Jazigo" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="despedida.sepHora" :rules="dateRules" label="Data" hide-details required
                        type="date" class="custom-text-field"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="despedida.velorio" :counter="100" label="Velório" hide-details required
                        class="custom-text-field"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="despedida.velHora" :rules="dateRules" label="Data" hide-details required
                        type="date" class="custom-text-field"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-col cols="12">
                    <v-textarea v-model="despedida.biografia" label="Biografia" outlined counter="1000" required
                      :rules="[v => v.length <= 1000 || 'Máximo de 1000 caracteres']" rows="5"
                      placeholder="Escreva uma breve biografia..." no-resize></v-textarea>
                  </v-col>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select v-model="despedida.privacyPost" label="Privacidade" :items="['Publica', 'Privada']"
                        required></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="despedida.privacyComments" label="Comentários Moderados?"
                        :items="['Sim', 'Não']" required></v-select>
                    </v-col>


                    <v-col cols="12">
                      <v-card elevation="0">
                        <v-radio-group v-model="despedida.banner" column>
                          <v-row dense>
                            <v-col v-for="(image, index) in images" :key="index" cols="12" md="6">
                              <v-card elevation="0">
                                <v-radio :value="image.id"></v-radio>
                                <img :src="require(`@/assets/pack-banners/${image.id}.jpg`)" width="400px"
                                  alt="Imagem de banner" />
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </v-card>
                    </v-col>
                  </v-row>

                </v-form>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card>

        <v-dialog v-model="dialogQRCODE" persistent max-width="500">
          <v-card>
            <v-toolbar color="#343F53">
              <v-card-title class="text-h5 grey lighten-2">
                <span class="font-weight-bold">QR Code</span>
              </v-card-title>
            </v-toolbar>

            <v-card-text class="d-flex flex-column align-center pb-0">
              <qrcode-vue :value="value_qrcode" :size="200" level="H" class="mb-4" />
              <p class="text-center grey--text">
                Escaneie o QR Code ou compartilhe com outros!
              </p>
            </v-card-text>
            <v-text-field v-model="value_qrcode" outlined readonly class="px-4" />

            <v-card-actions class="d-flex justify-end">
              <v-btn class="botao" color="primary" @click="copiarLink">
                <v-icon left dark>mdi-content-copy</v-icon>
                Copiar
              </v-btn>
              <v-btn class="botao" color="red" @click="finalizarQrCode">
                <v-icon left dark>mdi-close-circle</v-icon>
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>



      <template v-slot:actions="{ }">
        <v-row class="d-flex justify-space-between align-center px-4 mx-8" style="height: 60px;">
          <v-btn v-if="currentStep > 1" variant="elevated" color="#343F53" class="rounded-lg text-white elevation-2"
            @click="backButtonClick()">
            <v-icon start>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>

          <v-btn :color="currentStep === 3 ? 'green' : '#343F53'" class="rounded-lg text-white elevation-2"
            @click="handleButtonClick()">
            {{ currentStep === 3 ? 'Finalizar' : 'Próximo' }}
            <v-icon end v-if="currentStep < 3">mdi-arrow-right</v-icon>
            <v-icon end v-else>mdi-check</v-icon>
          </v-btn>
        </v-row>

      </template>
    </v-stepper>
    <v-container v-else-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="#343F53" size="50"></v-progress-circular>
    </v-container>
    <br />
  </v-card>
</template>

<script>
const urlBase = 'https://parquedasrecordacoes.com.br'
//const urlBase = 'http://localhost:8080'

import Navbar from './Navbar.vue';
import { http } from '../../services/config';
import QrcodeVue from 'qrcode.vue'
import authHeader from '@/services/auth-header';
import { useToast } from "vue-toastification"
import axios from 'axios';
const toast = useToast()

export default {
  components: {
    QrcodeVue, Navbar
  },

  computed: {
    isLastStep() {
      return this.currentStep === this.items.length;
    },
  },

  data() {
    return {
      
      loadingCEP: false,
      loadingCPF: false,
      loadingEnvioCod: false,
      loadingValidCod: false,
      loading: false,
      itemsPerPage: 10,
      showPassword: false,
      validacaoModerador: false,
      moderadorExistente: false,
      isVisibleToken: false,
      isVisible: false,
      isWaiting: false,
      tempoRestante: 0,
      selectedImage: null,
      images: [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }, { id: '6' }, { id: '7' }, { id: '8' }, { id: '9' }, { id: '10' }, { id: '11' }, { id: '12' },],
      dialogQRCODE: false,
      value_qrcode: '',
      size: 150,
      currentStep: 1,
      valid: false,
      dialogFalecido: false,
      dialogModerador: false,
      cpf: '',
      cpfResults: [],
      idFalecido: 0,
      idModerador: 0,
      falecido: {
        firstname: '',
        birthdate: '',
        deathdate: '',
        birthCity: '',
        birthState: '',
        birthCountry: '',
        profilePicture: '',
      },
      moderador: {
        nome: '',
        phone: '',
        email: '',
        password: '',
        cliente: '',
        parentesco: '',
        token: '',
        tipoParentesco: ''
      },
      despedida: {
        showPortal: false,
        contrato: '',
        cemiterio: '',
        jazigo: '',
        sepHora: '',
        velorio: '',
        velHora: '',
        biografia: '',
        banner: null,
        privacyPost: '',
        privacyComments: '',
      },
      headers: [
        { text: 'Informações', value: 'nome', title: 'Informações', sortable: false },
        { text: 'Ações', value: 'action', title: 'Ações', sortable: false }
      ],
      nameRules: [(v) => !!v || 'Nome é obrigatório'],
      dateRules: [(v) => !!v || 'Data é obrigatória'],
      cityRules: [(v) => !!v || 'Cidade é obrigatória'],
      emailRules: [
        (v) => !!v || 'E-mail é obrigatório',
        (v) => /.+@.+\..+/.test(v) || 'E-mail deve ser válido'
      ],
      passwordRules: [
        (v) => !!v || 'Senha é obrigatória',
        (v) => (v && v.length >= 6) || 'A senha deve ter pelo menos 6 caracteres'
      ],
      phoneRules: [(v) => !!v || 'Telefone é obrigatório'],
    };
  },
  methods: {
    searchCPF() {
      this.loadingCPF = true;
      http.get("/cliente/pessoas-ivertex", {
        params: {
          cpf: this.cpf
        },
        headers: authHeader()
      })
        .then(response => {
          this.cpfResults = response.data;
        })
        .catch(error => {
          console.error("Erro ao buscar CPF:", error);
        })
        .finally(() => {
          this.loadingCPF = false;
        });
    },
    consultarCEP() {
      const cep = this.falecido.cep.replace(/\D/g, '');
      if (cep.length === 8) {

        this.falecido.birthCountry = '';
        this.falecido.birthCity = '';
        this.falecido.birthState = '';

        this.loadingCEP = true;
        axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`)
          .then(response => {
            this.falecido.birthCountry = 'Brasil';
            this.falecido.birthCity = response.data.city;
            this.falecido.birthState = response.data.state;
          })
          .catch(error => {
            console.error('Erro ao consultar o CEP:', error);
            toast.error('CEP não localizado!')
          }).finally(() => {
            this.loadingCEP = false;
          });
      }
    },
    formatDate(date) {
      if (!date) return '';
      const isoDate = date.split('T')[0];
      const [year, month, day] = isoDate.split('-');
      return `${day}/${month}/${year}`;
    },

    maskCPF() {
      let cpf = this.cpf.replace(/\D/g, '')
      if (cpf.length > 11) cpf = cpf.slice(0, 11);

      if (cpf.length > 9) {
        cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
      } else if (cpf.length > 6) {
        cpf = cpf.replace(/(\d{3})(\d{3})(\d{1,3})/, '$1.$2.$3');
      } else if (cpf.length > 3) {
        cpf = cpf.replace(/(\d{3})(\d{1,3})/, '$1.$2');
      }

      this.cpf = cpf;
    },
    maskPhone(moderador) {
      moderador.phone = moderador.phone.replace(/\D/g, '');
      if (moderador.phone.length > 2) {
        moderador.phone = '(' + moderador.phone.slice(0, 2) + ') ' + moderador.phone.slice(2);
      }
      if (moderador.phone.length > 9) {
        moderador.phone = moderador.phone.slice(0, 10) + '-' + moderador.phone.slice(10);
      }
      if (moderador.phone.length > 15) {
        moderador.phone = moderador.phone.slice(0, 15);
      }
    },


    async enviarToken() {
      try {
        const phoneTratado = this.moderador.phone.replace(/\D/g, "");
        this.loadingEnvioCod = true;
        if (!phoneTratado || phoneTratado.length !== 11) {
          toast.error("Informe um número de telefone válido!");
          return;
        }

        const response = await http.post(`validacao/salvar`, null,
          {
            params: {
              telefone: phoneTratado,
            },
            headers: authHeader(),
          }
        );
        this.idDespedida = response.data.id;
      } catch (error) {
        console.error("Erro ao cadastrar despedida:", error);
        throw error;
      }
      this.loadingEnvioCod = false;
      this.isVisibleToken = true;
      this.codigoEnviado = true;
      this.iniciarTemporizador();
    },

    async verificarEmail() {
      try {
        const response = await http.post(
          `validacao/verificar-email`,
          null,
          {
            params: {
              email: this.moderador.email,
            },
            headers: authHeader(),
          }
        );
        console.log(response.data);
        return response.data;
      } catch (error) {
        console.error("Erro ao validar o token:", error);
        return false;
      }
    },
    async verificarToken() {

      if (!this.moderador.token) {
        toast.error("Informe o código de verificação!");
        return;
      }
      this.loadingValidCod = true;
      const phoneTratado = this.moderador.phone.replace(/\D/g, "");

      // Parar o temporizador ao validar o código
      this.pararTemporizador();

      try {

        const response = await http.post(
          `validacao/verificar`, // Endpoint
          null,
          {
            params: {
              telefone: phoneTratado,
              token: this.moderador.token,
            },
            headers: authHeader(),
          }
        );


        if (response.status === 200 && response.data.mensagem === "Sucesso") {
          toast.success("Código validado!");
          this.codigoValidado = true;
          this.isVisible = true;
          this.validacaoModerador = true;
          this.pararTemporizador();
        } else {
          toast.error("Código incorreto!");
        }

        if (response.data.existente) {
          this.moderador.nome = response.data.pessoa.nome;
          this.moderador.cliente = response.data.pessoa.moderador_cliente;
          this.moderador.parentesco = response.data.pessoa.parentesco_falecido;
          this.idModerador = response.data.pessoa.id;
          this.moderador.tipoParentesco = response.data.pessoa.tipo_parentesco;
          this.moderadorExistente = true;
        }
      } catch (error) {
        console.error("Erro ao validar o e-mail:", error);
      }
      this.loadingValidCod = false;
    },
    iniciarTemporizador() {
      this.isWaiting = true;
      this.tempoRestante = 150;

      // Se já houver um temporizador rodando, limpa ele antes de iniciar um novo
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        if (this.tempoRestante > 0) {
          this.tempoRestante--;
        } else {
          this.isWaiting = false;
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }, 1000);
    },
    pararTemporizador() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      this.isWaiting = false;
      this.tempoRestante = 0;
    },
    selectItem(item, itemType) {
      if (itemType === 'falecido') {
        this.falecido.firstname = item.nome;
        this.falecido.birthdate = item.nascimento;
        this.falecido.deathdate = item.falecimento;
        this.despedida.cemiterio = item.cemiterio;
        this.despedida.jazigo = item.localizacao;
        this.despedida.sepHora = item.dtSepultamento;
        this.despedida.velorio = item.velorio;
        this.despedida.velHora = item.dtVelorio;
        this.despedida.contrato = item.num_contrato
      } else if (itemType === 'moderador') {
        this.moderador.email = item.email || '';
        this.moderador.phone = item.telefone || '';
      }
      if (itemType === 'falecido') {
        this.dialogFalecido = false;
      } else if (itemType === 'moderador') {
        this.dialogModerador = false;
      }
    }, removeMask(value) {
      return value.replace(/\D/g, '');
    }, cleanDataBeforeSearch() {
      this.cpf = this.removeMask(this.cpf);
    },
    finalizarQrCode() {
      this.dialogQRCODE = false
      this.$router.push('despedidas')
    },
    copiarLink() {
      toast.success('Link copiado para área de transferência!');
    },
    handleButtonClick: async function () {
      if (this.currentStep === 1) {
        if (!this.validateStepOne()) {
          toast.error('Preencha todos os campos obrigatórios antes de avançar.');
          return;
        }
        if (!this.despedida.showPortal) {
          this.currentStep = 3;
          return;
        }
      }

      if (this.currentStep === 2) {
        if (this.idModerador == 0) {
          try {
            const emailValido = await this.verificarEmail();
            if (emailValido) {
              toast.error("E-mail já está registrado. Por favor informe outro para cadastro!");
              return;
            }
          } catch (error) {
            console.error("Erro ao verificar o e-mail:", error);
            toast.error("Erro ao verificar o e-mail.");
            return;
          }
        }
        if (!this.validacaoModerador) {
          toast.error('Validação do moderador necessária!');
          return;
        }
      }

      if (this.currentStep === 3) {
        this.finalizarCadastro();
      } else {
        this.currentStep++;
      }
    },
    backButtonClick() {
      if (!this.despedida.showPortal) {
        this.currentStep = 1
        return
      }
      this.currentStep--;
    },
    validateStepOne() {
      const { firstname, birthdate, deathdate, birthCity, birthState, birthCountry } = this.falecido;
      return firstname && birthdate && deathdate && birthCity && birthState && birthCountry;
    },


    async finalizarCadastro() {
      this.loading = true;

      try {
        await this.cadastrarFalecido();

        if (this.despedida.showPortal && !this.moderadorExistente) {
          await this.cadastrarModerador();
        }

        if (this.despedida.showPortal && this.moderadorExistente) {
          await this.updateModerador();
        }

        await this.cadastrarDespedida();

        toast.success('Cadastro finalizado com sucesso!');
        this.dialogQRCODE = true;
        this.loading = false;

      } catch (error) {
        console.error("Erro ao realizar os cadastros:", error);
        this.loading = false;
        toast.error('Erro ao cadastrar. Tente novamente mais tarde!');
      }

    },
    async updateModerador() {
      try {
        if (this.idModerador === 0) {
          return
        }
        const response = await http.post(
          "cliente/update-pessoa-moderador",
          {
            "pessoa": {
              "id": this.idModerador,
              "nome": this.moderador.nome,
              "telefone": this.moderador.phone,
              "moderador_cliente": this.moderador.cliente,
              "parentesco_falecido": this.moderador.parentesco,
              "tipo_parentesco": this.moderador.tipoParentesco
            },
          },
          {
            headers: authHeader()
          }
        );
        if (!response) {
          console.log('Finalizado!')
        }
      } catch (error) {
        console.error("Erro ao atualizar moderador:", error);
        throw error;
      }
    },
    async cadastrarFalecido() {
      try {
        const response = await http.post(
          "cliente/nova-pessoa-falecida",
          {
            "nome": this.falecido.firstname,
            "data_nascimento": this.falecido.birthdate,
            "data_falecimento": this.falecido.deathdate,
            "cidade": this.falecido.birthCity,
            "estado": this.falecido.birthState,
            "pais": this.falecido.birthCountry
          },
          {
            headers: authHeader()
          }
        );
        this.idFalecido = response.data.id;
      } catch (error) {
        console.error("Erro ao cadastrar falecido:", error);
        throw error;
      }
    },

    async cadastrarModerador() {
      try {
        const response = await http.post(
          "cliente/nova-pessoa-moderador",
          {
            "username": this.moderador.email,
            "password": this.moderador.password,
            "pessoa": {
              "nome": this.moderador.nome,
              "telefone": this.moderador.phone,
              "moderador_cliente": this.moderador.cliente,
              "parentesco_falecido": this.moderador.parentesco,
              "tipo_parentesco": this.moderador.tipoParentesco
            },

          },
          {
            headers: authHeader()
          }
        );
        this.idModerador = response.data.id;
      } catch (error) {
        console.error("Erro ao cadastrar moderador:", error);
        throw error;
      }
    },

    async cadastrarDespedida() {
      const fotoPerfilBase64 = await this.convertToBase64(this.falecido.profilePicture);

      try {
        const response = await http.post(
          "cliente/nova-despedida",
          {
            "foto_fundo": this.despedida.banner,
            "obituario": this.despedida.biografia,
            "data_sepultamento": this.despedida.sepHora,
            "local_sepultamento": this.despedida.cemiterio,
            "localizacao_sepultado": this.despedida.jazigo,
            "data_velorio": this.despedida.velHora,
            "local_velorio": this.despedida.velorio,
            "pessoa_falecida_id": this.idFalecido,
            "pessoa_moderador_id": this.idModerador,
            "foto_perfil": fotoPerfilBase64,
            "funeraria": "Funerária Exemplo Ltda",
            "status_memoria": true,
            "tipo": this.despedida.privacyPost,
            "com_moderacao": false,
            "transmissao": "https://example.com/transmissao",
            "codigo_transmissao": "TX123456",
            "contrato_jazigo": this.despedida.contrato,
            "publicacao": this.despedida.showPortal
          },
          {
            headers: authHeader()
          }
        );
        this.idDespedida = response.data.id;
        this.value_qrcode = urlBase + "/despedidas/" + response.data.controle.chave;
      } catch (error) {
        console.error("Erro ao cadastrar despedida:", error);
        throw error;
      }
    },
    // Função para converter o arquivo para Base64
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        if (!file) {
          // Retorna diretamente o caminho da imagem padrão
          return resolve(require('@/assets/perfil.avif'));
        }

        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }

  }
};
</script>

<style scoped>
.container {
  min-height: 70vh !important;
}

.custom-text-field {
  margin-bottom: 1rem;
}

.custom-file-input {
  margin-top: 1rem;
}

.search {
  margin-bottom: 1rem;
}

.finish {
  margin: 1rem;
}

.qrcode {
  text-align: center;
}

.img-banner {
  width: 300px;
}

.hidden-fields {
  visibility: hidden;
}
</style>
