
<template>
  <router-view />
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}
/* Para navegadores WebKit (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 6px; /* Define a largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Cor do fundo da barra de rolagem */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #343F53; /* Cor da alça (o que desliza) */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #232A3B; /* Cor ao passar o mouse */
}

/* Para Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #343F53 #f0f0f0;
}

</style>
