<script>
import Footer from '@/components/register/Footer.vue'
import { http } from '@/services/config';
import QrcodeVue from 'qrcode.vue'

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

export default {
  components: { Footer, QrcodeVue },
  mounted() {
    this.empresa = this.$route.params.empresa;
    this.id = this.$route.params.id;
    this.despedidas()
  },
  methods: {
    convertToEmbed(url) {
      if (!url) return '';
      // eslint-disable-next-line no-useless-escape
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([^&?\/]+)/;
      const match = url.match(regex);
      const videoId = match ? (match[1] || match[2]) : null;
      return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
    },
    formatDate(date) {
      return dayjs(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    async despedidas() {
      try {
        const response = await http.get(`exibicao/despedida/${this.empresa}/${this.id}`);
        this.despedida = response.data;
        this.falecido = this.despedida.pessoa_falecida;
        this.moderador = this.despedida.pessoa_moderador;
        this.value_qrcode = this.despedida.controle.token;

        // Após concluir despedidas, chama consultarMensagens()
        await this.consultarMensagens();
      } catch (error) {
        console.error("Erro ao consultar despedidas:", error);
      }
    },

    async consultarMensagens() {
      try {
        if (!this.despedida || !this.despedida.id) {
          console.error("Erro: despedida ainda não foi carregada.");
          return;
        }

        const response = await http.get(`exibicao/homenagem/${this.despedida.id}`);
        this.posts = response.data;
        this.loading = false;
      } catch (error) {
        console.error("Erro ao buscar mensagens:", error);
        this.loading = false;
      }
    },

    // Função para converter o arquivo para Base64
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        if (!file || !(file instanceof Blob)) {
          resolve(''); // Retorna uma string vazia se o arquivo for inválido
          return;
        }
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file); // Converte o arquivo para Base64
      });
    },

  },
  data: () => ({
    alert_msg: false,
    msg_dialog: '',
    mensagem: false,
    posts: [],
    post_new: {
      img: '',
      msg: '',
      autor: '',
      url: ''
    },
    id: null,
    empresa: null,
    falecido: {
    },
    moderador: {
    },
    despedida: {
      foto_fundo: 1
    },
    tab: "1",
    loading: true,
    value_qrcode: ''
  }),
}
</script>


<template>
  <section class="banner"
    :style="{ backgroundImage: `url(${require(`@/assets/pack-banners/${despedida.foto_fundo}.jpg`)})` }">
    <div class="imgperfil">
      <img v-if="!despedida.foto_perfil" src="../../assets/images/Elementos/branco.png" alt="">
      <img v-else :src="despedida.foto_perfil" alt="Foto de Perfil" />
    </div>
  </section>

  <section class="dados">
    <h1>{{ falecido.nome }}</h1>
    <div class="date">
      <div class="star">
        <img src="@/assets/images/icons/star.png" alt="Star icon">
      </div>
      <h2>{{ formatDate(falecido.data_nascimento) }}</h2>
      <div class="cross">
        <img src="@/assets/images/icons/cross.png" alt="Cross icon">
      </div>
      <h2>{{ formatDate(falecido.data_falecimento) }}</h2>
    </div>
  </section>

  <!-- v-container para as seções -->
  <v-container class="card-container">
    <v-row>
      <v-col cols="12" md="12">
        <h2 class="title">Biografia</h2>
        <p class="msgobtuario">{{ despedida.obituario }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <h2 class="title">Sepultamento</h2>
        <p class="info">
          <v-icon left>
            <img src="@/assets/images/icons/location.svg" alt="">
          </v-icon>
          {{ despedida.local_sepultamento }}
        </p>
        <p class="info">
          <v-icon left>
            <img src="@/assets/images/icons/info.svg" alt="">
          </v-icon>
          Jazigo: {{ despedida.localizacao_sepultado }}
        </p>
      </v-col>
    </v-row>
    <v-row class="pb-0">
      <v-col cols="12" md="12" class="pb-0">
        <h2 class="title">Mensagens</h2>
        <v-container fluid>
          <v-row>
            <v-col class="pb-0">
              <v-progress-circular v-if="loading" indeterminate size="64" color="primary"
                class="mx-auto d-flex justify-center"></v-progress-circular>
              <v-container v-else>
                <v-carousel cycle hide-delimiters height="auto" class="multi-carousel" :show-arrows="false"
                  interval="10000">
                  <v-carousel-item v-for="(item, index) in posts" :key="index">
                    <v-row justify="center">
                      <v-col cols="12" class="pb-0">
                        <v-card class="carousel-card pb-0" elevation="0">
                          <v-card-text class="carousel-content">
                            <v-card-text class="content-text">
                              "{{ item.descricao }}"
                            </v-card-text>
                            <v-card-text v-if="item.nome && item.data_create" class="content-info">
                              {{ item.nome }} - {{ formatDate(item.data_create) }}
                            </v-card-text>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-carousel-item>
                </v-carousel>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="d-flex flex-row justify-center py-0">
        <!-- QR Code -->
        <div class="qr-text-container mr-16">
          <qrcode-vue :value="value_qrcode" :size="size" level="H" />
          <p class="qr-text">
            Faça uma homenagem!
          </p>
        </div>
        <!-- QR Code -->
        <div class="qr-text-container">
          <qrcode-vue :value="value_qrcode" :size="size" level="H" />
          <p class="qr-text">
            Publique sua mensagem!
          </p>
        </div>
      </v-col>

    </v-row>
  </v-container>
  <Footer />
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

*,
*::before,
*::after {
  font-family: "Sora", sans-serif;
}

/* Estilo do banner */
.banner {
  height: 10vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.banner div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.banner div img {
  border-radius: 100%;
  width: 260px;
  height: 260px;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  object-fit: cover;
}

/* Estilo para as seções */
.dados {
  margin-top: 8rem;
}

.dados h1 {
  text-align: center;
  color: #343F53;
  font-weight: 900;
  font-size: 48px;
}

.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.star,
.cross {
  display: flex;
  align-items: center;
  justify-content: center;
}

.star img,
.cross img {
  width: 20px;
}

.cross {
  margin-left: 1rem;
}

.date h2 {
  text-align: center;
  color: #343F53;
  font-weight: 300;
  font-size: 20px;
}

.card-container {
  min-height: 64vh;
}

/* Títulos e conteúdo da biografia e localização */
.title {
  color: #343F53;
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
  padding-top: 1rem;
}

.title::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #343F53;
  position: absolute;
  bottom: 0;
  left: 0;
}

.subtitle {
  color: #343F53;
  font-size: 1.6rem;
  font-weight: 500;
}

.msgobtuario {
  margin-top: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343F53;
  font-size: 1.2rem;
  text-align: justify;
}

.info {
  width: 36vw;
  text-align: justify;
}

/* Estilo do Carrossel */
.carousel-media_img {
  filter: brightness(0) invert(20%) sepia(80%) saturate(500%) hue-rotate(180deg);
  width: 10rem;
}

.multi-carousel .v-carousel-item {
  height: 18vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  padding-bottom: 0;
}

.carousel-card {
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem auto;
  width: 70%;
  height: 12vh;
  padding: 0 !important;
}

.carousel-media {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40%;
}

.media-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.media-video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.carousel-media.empty {
  height: 50%;
}

.carousel-divider {
  width: 90%;
  height: 1px;
  background-color: #343F53;
  margin: 0.5rem 0;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;
}


.content-text {
  color: #343F53;
  font-size: 1.8rem;
  line-height: 1.4;
  padding: 0;
  max-height: 10vh !important;
  font-style: italic;
  font-family: "Roboto", sans-serif;
}

.content-info {
  font-size: 1.4rem;
  color: #5a5a5a;
  padding: 0.2rem !important;
}


.qr-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.2rem;
}

.qr-text {
  color: #343F53;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}
</style>
