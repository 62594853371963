import axios from "axios";

const url = process.env.VUE_APP_API_URL;

export const http = axios.create({
  baseURL: url,
});

http.defaults.timeout = 300000;

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  url
}