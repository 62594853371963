<template class="main">
  <Navbar :Msg="'Sair'" :rota="'admin/entrar'" />
  <v-container class="container">
    <v-card class="mx-auto table-pai" max-width="1000" elevation="0" style="border: 1px solid #343F53;">
      <v-toolbar color="#343F53">
        <v-card-title class="headline font-weight-bold d-flex align-center justify-space-between px-8 w-100">
          <spam style="color: white;">Despedidas</spam>
          <v-btn color="white" small class="mr-2" style="font-weight:bold" @click="novoPost()">
            NOVA DESPEDIDA
            <v-icon class="ml-2" dark>mdi-plus-thick</v-icon>
          </v-btn>
        </v-card-title>
      </v-toolbar>


      <v-card-text>
        <v-data-table :headers="headers" :items="serverItems" :items-per-page="itemsPerPage" :loading="loading"
          items-per-page-text="Despedidas por página" :pageText="'{0}-{1} de {2}'"
          :sort-by="[{ key: 'id', order: 'desc' }]">

          <template #item.foto="{ item }">
            <div>
              <img :src="item.foto_perfil" alt="Foto do Perfil" class="foto_perfil" />
            </div>
          </template>

          <template #item.nome="{ item }">
            <div class="d-flex flex-column flex-md-row my-4">
              <div class="flex-grow-1">
                <strong>Código:</strong> {{ item.id }} <br>
                <strong>Nome:</strong> {{ item.pessoa_falecida.nome }} <br>
                <strong>Cemitério:</strong> {{ item.local_sepultamento }}
                <strong>Jazigo:</strong> {{ item.localizacao_sepultado }} <br>
                <strong>Data Sepultamento:</strong> {{ formatDate(item.data_sepultamento) }} <br>
                <strong>Moderador:</strong> {{ item.pessoa_moderador.nome }}
                <strong>Telefone:</strong> {{ maskPhone(item.pessoa_moderador.telefone) }} <br>
                <strong>Data Criação:</strong> {{ formatDate(item.dataCreate) }} <br>
                <v-switch @click="modificarStatusDespedida(item)" inset
                  :label="item.status_memoria ? 'Ativo' : 'Desativado'" color="green" v-model="item.status_memoria"
                  :loading="loadingStatus" :disabled="loadingStatus"></v-switch>
              </div>
              <div class="ml-md-4 d-flex flex-row">
                <v-btn density="compact" rounded="lg" variant="text" @click="abrirDespedida(item.id)" color="#343F53">
                  <v-icon size="24">mdi-eye</v-icon>
                </v-btn>

                <v-btn density="compact" rounded="lg" variant="text" @click="gerarQrCode(item.controle.chave)"
                  color="#343F53">
                  <v-icon size="24">mdi-qrcode</v-icon>
                </v-btn>

                <v-btn density="compact" rounded="lg" variant="text" @click="gerenciar(item.id)" color="#343F53">
                  <v-icon size="24">mdi-pencil</v-icon>
                </v-btn>
                <v-tooltip location="top"
                  :text="item.apresentacao ? `${item.status_apresentacao ? 'AOVIVO' : 'OFFLINE'}: ${item.apresentacao.empresa} - ${item.apresentacao.capela}` : 'Sem informações'"
                  color="red">
                  <template v-slot:activator="{ props }">
                    <v-btn size="x-small" density="compact" rounded="lg" variant="text"
                      @click="abrirModalCapela(item.id)"
                      :color="item.status_apresentacao ? 'red' : '#343F53'" v-bind="props">
                      <v-icon size="24">
                        {{ item.status_apresentacao ? 'mdi-television-play' : 'mdi-television' }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>




              </div>

            </div>
            <!-- <div class="actions-container">
              <div class="action-item">
                <v-btn small class="botao" @click="gerenciar(item.id)" type="submit" color="primary">
                  Editar
                  <v-icon class="icon" dark>mdi-pencil</v-icon>
                </v-btn>
              </div>

            </div> -->

          </template>
        </v-data-table>
        <v-col cols="12" class="text-end">

        </v-col>
      </v-card-text>

    </v-card>

  </v-container>
  <v-dialog v-model="dialogQrCode" persistent max-width="500">
    <v-card>
      <v-toolbar color="#343F53">
        <v-card-title class="text-h5 grey lighten-2">
          <span class="font-weight-bold">QR Code</span>
        </v-card-title>
      </v-toolbar>

      <v-card-text class="d-flex flex-column align-center pb-0">
        <qrcode-vue :value="value_qrcode" :size="200" level="H" class="mb-4" />
        <p class="text-center grey--text">
          Escaneie o QR Code ou compartilhe com outros!
        </p>
      </v-card-text>
      <v-text-field v-model="value_qrcode" outlined readonly class="px-4" />

      <v-card-actions class="d-flex justify-end">
        <v-btn text class="botao" color="#343F53" @click="dialogQrCode = false">

          <v-icon left dark>mdi-close-circle</v-icon>
          Fechar
        </v-btn>
        <v-btn variant="outlined" class="botao" color="#343F53" @click="copiarLink">
          <v-icon left dark>mdi-content-copy</v-icon>
          Copiar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialoCapela" persistent max-width="500">
    <v-card>
      <v-toolbar color="#343F53">
        <v-card-title class="text-h5 grey lighten-2">
          <span class="font-weight-bold">Exibição Capela</span>
        </v-card-title>
      </v-toolbar>
      <v-row class="my-8 mx-4">
        <v-col cols="12" class="py-0">
          <v-select v-model="capela.empresa" :items="unidades" item-title="nome" item-value="sigla"
            label="Empresa"></v-select>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-select :items="capelasDisponiveis" label="Número" v-model="capela.capela"></v-select>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-switch inset :label="capela.status ? 'Ativo' : 'Desativado'" color="green"
            v-model="capela.status"></v-switch>
        </v-col>
      </v-row>

      <v-card-actions class="d-flex justify-end">
        <v-btn text class="botao" color="#343F53" @click="fecharModalCapela()">
          <v-icon left dark>mdi-close-circle</v-icon>
          Fechar
        </v-btn>
        <v-btn variant="outlined" class="botao" color="#343F53" @click="salvarCapela()">
          <v-icon left dark>mdi-check-bold</v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

const urlBase = 'https://parquedasrecordacoes.com.br'
//const urlBase = 'http://localhost:8080'
import { useToast } from "vue-toastification"
const toast = useToast()
import { http } from '@/services/config'
import Navbar from './Navbar.vue'
import QrcodeVue from 'qrcode.vue'
import authHeader from '@/services/auth-header';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

export default {
  components: {
    Navbar, QrcodeVue,
  },
  computed: {
    capelasDisponiveis() {
      // Encontrar o nome da unidade baseado na sigla selecionada
      const unidadeSelecionada = this.unidades.find(u => u.sigla === this.capela.empresa);
      return unidadeSelecionada ? this.capelasPorUnidade[unidadeSelecionada.nome] || [] : [];
    },
  },
  data: () => ({
    unidades: [
      { nome: "Central de Velórios", sigla: "CV" },
      { nome: "Parque das Flores", sigla: "PF" },
      { nome: "Memorial Parque Maceió", sigla: "ME" },
      { nome: "Cemitério Previda", sigla: "CP" },
      { nome: "Parque do Agreste", sigla: "AM" },
    ],
    capelasPorUnidade: {
      "Memorial Parque Maceió": ["01", "02", "03", "04", "05", "06"],
      "Central de Velórios": ["01", "02", "03", "04"],
      "Parque das Flores": ["01", "02", "03", "04"],
      "Cemitério Previda": ["01", "02", "03"],
      "Parque do Agreste": ["01", "02", "03"]
    },
    capela: {
      id: 0,
      id_despedida: 0,
      empresa: '',
      capela: '',
      status: true
    },
    toolTipCapela: {
      empresa: '',
      capela: '',
      status: false
    },
    loadingTooltip: false,
    dialoCapela: false,
    loadingStatus: false,
    itemsPerPage: 10,
    headers: [
      { text: 'Avatar', value: 'foto', title: 'Avatar', sortable: false },
      { text: 'Informações', value: 'nome', title: 'Informações', sortable: false },
    ],
    search: '',
    serverItems: [
    ],
    loading: true,
    totalItems: 0,
    dialogQrCode: false,
    value_qrcode: '',
  }),
  mounted() {
    this.despedidas()
  },
  methods: {
    // Fechar o pop-up e restaurar objeto para o padrão
    fecharModalCapela() {
      this.dialoCapela = false;
      this.capela = {
        id: 0,
        id_despedida: 0,
        empresa: '',
        capela: '',
        status: true
      }
    },
    // Abrir o pop-up e verificar se a despedida já possui capela cadastrada
    async abrirModalCapela(idDespedida) {
      this.dialoCapela = true;
      this.capela.id_despedida = idDespedida;

      try {
        const capelaExistente = await this.buscarCapela(idDespedida);
        if (capelaExistente.length > 0) {
          this.capela = { ...capelaExistente[0] }; // Preencher os campos com os dados retornados
        }
      } catch (error) {
        console.error("Erro ao buscar capela:", error);
        toast.error("Erro ao carregar capela.");
      }
    },

    // Buscar capela associada à despedida
    async buscarCapela(idDespedida) {
      try {
        const response = await http.get(`apresentacao/despedida/${idDespedida}`, {
          headers: authHeader(),
        });

        return Array.isArray(response.data) ? response.data : []; // Garantir que seja um array
      } catch (error) {
        console.error("Erro ao consultar capela:", error);
        return []; // Retorna array vazio para evitar erro na atribuição
      }
    },

    // Salvar ou atualizar capela com base na existência do ID
    async salvarCapela() {
      if (!this.capela.empresa || !this.capela.capela || !this.capela.id_despedida) {
        toast.error("Preencha todos os campos obrigatórios!");
        return;
      }

      if (this.capela.id > 0) {
        await this.atualizarCapela();
      } else {
        await this.cadastrarCapela();
      }
    },

    // Atualizar capela já existente
    async atualizarCapela() {
      try {
        await http.put("apresentacao/update", this.capela, { headers: authHeader() });

        this.dialoCapela = false;
        toast.success("Capela editada com sucesso!");
      } catch (error) {
        console.error("Erro ao editar capela:", error);
        toast.error("Erro ao editar capela.");
      }
    },

    // Criar uma nova capela
    async cadastrarCapela() {
      try {
        await http.post("apresentacao/salva", this.capela, { headers: authHeader() });

        this.dialoCapela = false;
        toast.success("Capela salva com sucesso!");
      } catch (error) {
        console.error("Erro ao cadastrar capela:", error);
        toast.error("Erro ao cadastrar capela.");
      }
    },
    async modificarStatusDespedida(item) {
      this.loadingStatus = true
      try {
        const response = await http.post(
          "cliente/update-despedida-status",
          {
            "id": item.id,
            "status_memoria": !item.status_memoria
          },
          {
            headers: authHeader()
          }
        );
        console.log('depois' + item.status_memoria)
        toast.success(`Despedida ${!item.status_memoria ? 'ativada' : 'desativada'} com sucesso!`);
        if (!response) {
          console.log(response)
        }
        this.despedidas()
      } catch (error) {
        console.error("Erro ao cadastrar despedida:", error);
        throw error;
      }
      this.loadingStatus = false;
    },
    copiarLink() {
      console.log('copiar!')
      toast.success('Link copiado para área de transferência!');
    },
    compartilharQrCode() {
      console.log('compartilhar!')
    },
    maskPhone(phone) {
      phone = phone.replace(/\D/g, '');
      if (phone.length > 2) {
        phone = '(' + phone.slice(0, 2) + ') ' + phone.slice(2);
      }
      if (phone.length > 10) {
        phone = phone.slice(0, 10) + ' ' + phone.slice(10);
      }
      if (phone.length > 15) {
        phone = phone.slice(0, 15);
      }
      return phone;
    },
    formatDate(date) {
      return dayjs(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    despedidas() {
      this.loading = true;
      http.get("cliente/despedidas", {
        headers: authHeader()
      })
        .then(response => {
          // Formata os campos de data
          this.serverItems = response.data
          this.totalItems = response.data.length;
          this.loading = false;
        })
        .catch(error => {
          console.error('Erro ao consultar:', error);
          this.loading = false;
        });
    },
    novoPost() {
      this.$router.push('/admin/criar');
    },
    gerenciar(cod) {
      this.$router.push('/admin/informacoes/' + cod);
    },
    abrirDespedida(cod) {
      this.$router.push(`/despedida/${cod}`);
    },
    gerarQrCode(cod) {
      this.dialogQrCode = true
      this.value_qrcode = urlBase + '/despedidas/' + cod;
    }
  },
}
</script>

<style scoped>
.container {
  min-height: 78vh;
}

.table-pai {
  margin: 4vh 0;
}

.foto_perfil {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100%;
}

.botao {
  margin-bottom: 0.6rem;
  margin-right: 0.4rem;
  font-weight: bold;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 1rem;
}

.action-item {
  margin-left: 8px;
}

.icon {
  margin-left: 8px;
}
</style>