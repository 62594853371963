import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';

// Toastification
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Máscara
import VueTheMask from 'vue-the-mask';

const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);

const toastOptions = {
  position: POSITION.TOP_RIGHT, 
  timeout: 3000, 
  closeOnClick: true, 
  pauseOnHover: true, 
};

app.use(VueTheMask);

app
  .use(vuetify)
  .use(store)
  .use(router)
  .use(Toast, toastOptions)
  .mount("#app");
