<template>
  <Navbar :msg="''" />
  <section class="container">
    <v-card class="content" max-width="1600px" min-height="600px" elevation="0">
      <v-card-title class="headline d-flex align-start justify-space-between flex-column px-4 w-100">
        <h1 style="color: #343F53;">RECORDAÇÕES ENCONTRADAS</h1>
      </v-card-title>
      <v-card-text class="d-flex flex-column align-center justify-center">
        <v-container>
          <v-row justify="center">
            <v-col v-for="(item, index) in despedidas" :key="index" cols="12" sm="6" md="4" lg="3">
              <v-card elevation="0" class="container-falecidos" @click="toDespedida(item.id)">
                <div>
                  <img v-if="item.foto_perfil === null" src="@/assets/perfil.avif" alt="" class="foto_perfil">
                  <img v-else :src="item?.foto_perfil" alt="Foto do Perfil" class="foto_perfil" />
                </div>
                <v-card-title class="headline font-weight-bold nome-falecido">
                  {{ item.pessoa_falecida?.nome }}
                </v-card-title>
                <v-card-text class="data-falecimento">
                  <div class="date">
                    <div class="cross">
                      <img src="@/assets/images/icons/cross.png" alt="Cross icon" />
                    </div>
                    <h2>{{ formatDate(item.pessoa_falecida?.data_falecimento) }}</h2>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </section>
  <Footer />
</template>


<script>
import Footer from '../register/Footer.vue';
import Navbar from '../register/Navbar.vue';
import { http } from '@/services/config';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

export default {
  name: 'ListaPostagens',
  components: {
    Navbar, Footer
  },
  data() {
    return {
      token: '',
      despedidas: [
      ]
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    this.getDespedidas(this.token);
  },
  methods: {
    formatDate(date) {
      return dayjs(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    getDespedidas(token) {
      http.get("exibicao/despedida-chave/" + token)
        .then(response => {
          this.despedidas = response.data;
        })
        .catch(error => {
          console.error('Erro ao consultar:', error);
        });
    },
    toDespedida(id) {
      this.$router.push(`/despedida/${id}`);
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
}

.container {
  height: 100%;
  min-height: 74vh;
  margin-bottom: 2rem;
}

.content {
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.container-falecidos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
}

.foto_perfil {
  width: 230px;
  height: 230px;
  object-fit: cover;
  border-radius: 100%;
}

.nome-falecido {
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  font-size: 1.1rem;
}

.data-falecimento {
  padding: 0 !important;
}

.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.cross {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cross img {
  width: 16px;
}

.date h2 {
  font-size: 1rem;
}
</style>
