<template>
  <Navbar :Msg="'Voltar'" :rota="'admin/despedidas'" />
  <div class="container">
    <v-card class="mx-auto content" max-width="1000" elevation="0">
      <v-toolbar color="#343F53">
        <v-card-title class="headline font-weight-bold d-flex align-center justify-space-between px-4 w-100">
          <spam style="color: white;">Gerenciamento Despedida [{{ despedida.id }}]</spam>
        </v-card-title>

      </v-toolbar>
      <v-card elevation="0">
        <v-tabs v-model="tab" align-tabs="center" color="#343F53">
          <v-tab :value="1">Falecido</v-tab>
          <v-tab :value="2">Moderador</v-tab>
          <v-tab :value="3">Despedida</v-tab>
        </v-tabs>

        <v-tabs-window v-model="tab">
          <v-tabs-window-item :key="1" :value="1">
            <v-card-text>
              <v-form v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="falecido.nome" :counter="100" label="Nome" hide-details required
                      class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="falecido.data_nascimento" label="Data de Nascimento" hide-details required
                      type="date" class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="falecido.data_falecimento" label="Data de Falecimento" hide-details
                      type="date" class="custom-text-field" required></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="falecido.cidade" label="Cidade de Nascimento" hide-details required
                      class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select v-model="falecido.estado" :items="states" label="Estado de Nascimento" hide-details
                      required class="custom-text-field"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select v-model="falecido.pais" :items="countries" label="País de Nascimento" hide-details
                      required class="custom-text-field"></v-select>
                  </v-col>
                  <div class="text-center pa-4 d-flex w-100 align-center justify-space-center">
                    <v-btn :disabled="loadingFalecido" :loading="loadingFalecido" variant="outlined" color="#343F53"
                      @click="updateFalecido()">
                      Atualizar
                    </v-btn>
                  </div>
                </v-row>
              </v-form>

            </v-card-text>
          </v-tabs-window-item>
          <v-tabs-window-item :key="2" :value="2">
            <v-card-text>
              <v-form v-model="valid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="moderador.nome" label="Nome" hide-details required
                      class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="moderador.telefone" label="Telefone do Moderador" hide-details
                      @input="maskPhone(moderador)" required class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="moderador.email" label="E-mail do Moderador" hide-details required
                      class="custom-text-field" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="moderador.password" label="Senha do Moderador" hide-details type="password"
                      required class="custom-text-field" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select v-model="moderador.moderador_cliente" label="Moderador é cliente?"
                      :items="['Sim, cliente do Grupo', 'Ainda não']" required></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select v-model="moderador.parentesco_falecido" label="Parentesco com falecido?"
                      :items="['Não informado', 'Sim', 'Não']" required></v-select>
                  </v-col>
                  <div class="text-center pa-4">
                    <v-btn :disabled="loadingModerador" :loading="loadingModerador" variant="outlined" color="#343F53"
                      @click="updateModerador()">
                      Atualizar
                    </v-btn>
                  </div>
                </v-row>
              </v-form>

            </v-card-text>
          </v-tabs-window-item>
          <v-tabs-window-item :key="3" :value="3">
            <v-card-text>
              <v-form v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-switch inset v-model="despedida.publicacao" label="Publicar no Portal" color="blue"></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="despedida.local_sepultamento" :counter="100" label="Cemitério" hide-details
                      required class="custom-text-field"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="despedida.localizacao_sepultado" :counter="100" label="Jazigo" hide-details
                      required class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="despedida.data_sepultamento" label="Data e Hora" hide-details required
                      type="date" class="custom-text-field"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="despedida.local_velorio" :counter="100" label="Velório" hide-details required
                      class="custom-text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="despedida.data_velorio" label="Data e Hora" hide-details required type="date"
                      class="custom-text-field"></v-text-field>
                  </v-col>
                </v-row>
                <v-col cols="12">
                  <v-textarea v-model="despedida.obituario" label="Biografia" outlined counter="1000" required
                    :rules="[v => v.length <= 1000 || 'Máximo de 1000 caracteres']" rows="5"
                    placeholder="Escreva uma breve biografia..." no-resize></v-textarea>
                </v-col>
                <v-row>
                  <v-col cols="3">
                    <v-switch inset v-model="despedida.capela.status" label="Vincular a Capela" color="blue"></v-switch>
                  </v-col>
                  <v-col cols="4" v-if="despedida.capela.status">
                    <v-select v-model="despedida.capela.unidade" :items="unidades" label="Unidade"></v-select>
                  </v-col>
                  <v-col cols="4" v-if="despedida.capela.status">
                    <v-select :items="capelasDisponiveis" label="Número" v-model="despedida.capela.numero"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select v-model="vazio" label="Privacidade" :items="['Publica', 'Privada']" required></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select v-model="vazio" label="Comentários Públicos" :items="['Sim', 'Não']" required></v-select>
                  </v-col>
                  <v-col cols="12">
                    <img width="160px" :src="despedida.foto_perfil" alt="Foto de Perfil" />
                    <v-file-input v-model="falecido.profilePicture" label="Foto de Perfil" hide-details accept="image/*"
                      class="custom-file-input"></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-card elevation="0">
                      <v-radio-group v-model="despedida.foto_fundo" column>
                        <v-row dense>
                          <v-col v-for="(image, index) in images" :key="index" cols="12" md="6">
                            <v-card>
                              <v-radio :value="image.id"></v-radio>
                              <img :src="require(`@/assets/pack-banners/${image.id}.jpg`)" width="400px"
                                alt="Imagem de banner" />
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-col>
                  <div class="text-center pa-4">
                    <v-btn :disabled="loadingDespedida" :loading="loadingDespedida" variant="outlined" color="#343F53"
                      @click="updateDespedida()">
                      Atualizar
                    </v-btn>
                  </div>
                </v-row>
              </v-form>
            </v-card-text>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card>
    </v-card>
  </div>
  <Footer />

</template>

<script>
import { useToast } from "vue-toastification"
const toast = useToast()
import Footer from './Footer.vue';
import Navbar from './Navbar.vue';
import { http } from '@/services/config';
import authHeader from '@/services/auth-header';

export default {
  name: 'Informacoes',
  components: {
    Navbar, Footer
  },
  data() {
    return {
      unidades: [
        "Central de Velórios",
        "Parque das Flores",
        "Memorial Parque Maceió",
        "Cemitério Previda",
        "Parque do Agreste"
      ],
      capelasPorUnidade: {
        "Memorial Parque Maceió": ["01", "02", "03", "04", "05", "06"],
        "Central de Velórios": ["01", "02", "03", "04"],
        "Parque das Flores": ["01", "02", "03", "04"],
        "Cemitério Previda": ["01", "02", "03", "04"],
        "Parque do Agreste": ["01", "02", "03", "04"]
      },
      loadingFalecido: false,
      loadingModerador: false,
      loadingDespedida: false,
      vazio: '',
      id: null,
      tab: null,
      selectedImage: null,
      images: [
        { id: '1' },
        { id: '2' },
        { id: '3' },
        { id: '4' },
        { id: '5' },
        { id: '6' },
        { id: '7' },
        { id: '8' },
        { id: '9' },
        { id: '10' },
        { id: '11' },
        { id: '12' },
      ],
      falecido: {
      },
      moderador: {
      },
      despedida: {

      },
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.despedidas()
  },
  computed: {
    capelasDisponiveis() {
      return this.capelasPorUnidade[this.despedida.capela.unidade] || [];
    }
  },
  methods: {
    finalizar() {
      this.$router.push('/admin/despedidas')
    },
    formatDate(date) {
      if (!date) return '';
      const isoDate = date.split('T')[0];
      const [year, month, day] = isoDate.split('-');
      return `${day}/${month}/${year}`;
    },
    despedidas() {
      http.get(`exibicao/despedida/${this.id}`)
        .then(response => {
          this.despedida = response.data;
          Object.assign(this.despedida, {
            capela: {
              status: false,
              unidade: '',
              numero: 0
            }
          });
          this.falecido = this.despedida.pessoa_falecida;
          this.moderador = this.despedida.pessoa_moderador;
        })
        .catch(error => {
          console.error('Erro ao consultar:', error);
        });
    },

    async updateFalecido() {
      this.loadingFalecido = true;
      try {
        const response = await http.post(
          "cliente/update-pessoa-falecida",
          {
            "id": this.falecido.id,
            "nome": this.falecido.nome,
            "data_nascimento": this.falecido.data_nascimento,
            "data_falecimento": this.falecido.data_falecimento,
            "cidade": this.falecido.cidade,
            "estado": this.falecido.estado,
            "pais": this.falecido.pais
          },
          {
            headers: authHeader()
          }
        );
        toast.success('Atualização finalizada com sucesso!');
        this.$router.push('/admin/despedidas')
        if (!response) {
          console.log(response)
        }
      } catch (error) {
        console.error("Erro ao atualizar falecido:", error);
        throw error;
      }
      this.loadingFalecido = false;
    },

    async updateModerador() {
      this.loadingModerador = true;
      try {
        const response = await http.post(
          "cliente/update-pessoa-moderador",
          {
            "pessoa": {
              "id": this.moderador.id,
              "nome": this.moderador.nome,
              "telefone": this.moderador.telefone,
              "moderador_cliente": this.moderador.cliente,
              "parentesco_falecido": this.moderador.parentesco,
              "tipo_parentesco": this.moderador.tipoParentesco
            },

          },
          {
            headers: authHeader()
          }
        );
        toast.success('Atualização finalizada com sucesso!');
        this.$router.push('/admin/despedidas')
        if (!response) {
          console.log(response)
        }
      } catch (error) {
        console.error("Erro ao atualizar moderador:", error);
        throw error;
      }
      this.loadingModerador = false;
    },

    async updateDespedida() {
      this.loadingDespedida = true;
      this.despedida.foto_perfil
      let fotoPerfilBase64 = this.despedida.foto_perfil;

      if (this.falecido.profilePicture != null) {
        fotoPerfilBase64 = await this.convertToBase64(this.falecido.profilePicture);
      }


      try {
        const response = await http.post(
          "cliente/update-despedida",
          {
            "id": this.despedida.id,
            "foto_perfil": fotoPerfilBase64,
            "funeraria": this.despedida.funeraria,
            "data_velorio": this.despedida.data_velorio,
            "local_velorio": this.despedida.local_velorio,
            "codigo_jazigo": this.despedida.codigo_jazigo,
            "data_sepultamento": this.despedida.data_sepultamento,
            "local_sepultamento": this.despedida.local_sepultamento,
            "localizacao_sepultado": this.despedida.localizacao_sepultado,
            "foto_fundo": this.despedida.foto_fundo,
            "contrato_plano": this.despedida.contrato_plano,
            "contrato_jazigo": this.despedida.contrato_jazigo,
            "dataCreate": this.despedida.dataCreate,
            "data_update": this.despedida.data_update,
            "user_create": this.despedida.user_create,
            "user_update": this.despedida.user_update,
            "status_memoria": this.despedida.status_memoria,
            "tipo": this.despedida.tipo,
            "com_moderacao": this.despedida.com_moderacao,
            "transmissao": this.despedida.transmissao,
            "codigo_transmissao": this.despedida.codigo_transmissao,
            "obituario": this.despedida.obituario,
            "publicacao": this.despedida.publicacao
          },
          {
            headers: authHeader()
          }
        );
        toast.success('Atualização finalizada com sucesso!');
        this.$router.push('/admin/despedidas')
        if (!response) {
          console.log(response)
        }
      } catch (error) {
        console.error("Erro ao cadastrar despedida:", error);
        throw error;
      }
      this.loadingDespedida = false;
    },

    // Função para converter o arquivo para Base64
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file); // Converte o arquivo para Base64
      });
    },

  }
}
</script>

<style scoped>
.container {
  min-height: 78vh !important;
}

.content {
  margin: 4vh 0;
}
</style>