<template>
  <footer>
    <div class="container">
      <div class="logomain">
        <img src="../../assets/images/logos/GRUPOPARQUE.png" alt="Grupo Parque das Flores Logo">
      </div>
      
      <hr>
      <p>
        2025 Grupo Parque das Flores. Todos os direitos reservados.
            R. Des. Barreto Cardoso, 317 - Gruta de Lourdes, Maceió - AL,
            57052-850
      </p>
    </div>
  </footer>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
}
footer .container {
  min-height: 14vh;
  background-color: #343F53;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  gap: 0.5rem;
}

p {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 100;
  text-align: center;
  padding: 0rem 1rem;
}

hr {
  min-width: 25rem;
}

.logomain {
  margin: 0.8rem;
  width: 150px;
  height: 36px;
}

.logomain img {
  width: 100%;
  height: 100%;
}
.end {
  text-align: center;
}

@media screen and (max-width: 815px) {
  p {
    padding: 0 2rem;
  }
  img {
    width: 80%;
  }
}
</style>
